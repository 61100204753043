import React, { useContext, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@bit/together-price.core.components.button';
import { useApp } from '@bit/together-price.core.components.app-context';
import { resendEmailVerification, sendCellphoneVerificationCode } from '@/api/create';
import AuthContext, { ACTIONS } from '@/components/AuthContext';
import SpinnerAdornment from '@/components/SpinnerAdornment';
import { EMAIL_NOT_VERIFIED_ERROR_MESSAGE } from '@/App/Routes/Auth/Auth';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    margin: theme.spacing(6, 0),
  },
  continueBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    height: 56,
    borderRadius: theme.spacing(2),
  }
}));

type EmailNotVerifiedStepProps = {};

const EmailNotVerifiedStep: React.FC<EmailNotVerifiedStepProps> = () => {
  const [isEmailResendLoading, setIsEmailResendLoading] = useState(false);
  const [isVerificationCodeLoading, setIsVerificationCodeLoading] = useState(false);
  const { setError } = useApp();
  const classes = useStyles();
  const { state, dispatch } = useContext(AuthContext);
  const { t } = useTranslation();

  const sendVerificationCode = () => {
    setIsVerificationCodeLoading(true);

    sendCellphoneVerificationCode({
      cellphone: state.phone,
      whatsappPermission: false,
    })
      .then(() => {
        dispatch({ type: ACTIONS.SET_STEP, payload: 3 });
      })
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);

        if (message === EMAIL_NOT_VERIFIED_ERROR_MESSAGE) {
          setError(
            <Trans
              i18nKey='PinLogin.emailNotVerifiedError'
            />
          );
          return;
        }

        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      })
      .finally(() => {
        setIsVerificationCodeLoading(false);
      });
  };

  const resendEmail = () => {
    setIsEmailResendLoading(true);

    resendEmailVerification()
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);

        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      })
      .finally(() => {
        setIsVerificationCodeLoading(false);
      });
  };

  return (
    <Grid container direction='column' className={classes.stepContainer}>
      <Typography
        className={classes.description}
        variant='subtitle2'
        color='textSecondary'
      >
        {t('PinLogin.Stepemail_not_verifiedDescription')}
      </Typography>
      <Button
        variant='contained'
        color='primary'
        className={classes.continueBtn}
        onClick={sendVerificationCode}
        disabled={isVerificationCodeLoading}
        fullWidth
      >
        {isVerificationCodeLoading ? <SpinnerAdornment /> : t('PinLogin.emailNotVerifiedTryAgain')}
      </Button>
      <Button
        variant='text'
        color='primary'
        className={classes.continueBtn}
        onClick={resendEmail}
        disabled={isEmailResendLoading}
        fullWidth
      >
        {isEmailResendLoading ? <SpinnerAdornment /> : t('PinLogin.emailNotVerifiedResend')}
      </Button>
    </Grid>
  );
};

export default EmailNotVerifiedStep;
