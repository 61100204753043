import React, { useState, useContext, useEffect } from 'react';
import {
  fade,
  Box,
  Grid,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import MobileKeyboard from '@bit/together-price.core.components.mobile-keyboard';
import PinNumberInput from '@bit/together-price.core.components.pin-number-input';
import SmartPhone from '@bit/together-price.core.icons.smart-phone';
import useDeviceService from '@bit/together-price.core.hooks.use-device';
import { useApp } from '@bit/together-price.core.components.app-context';
import { registerDevice } from '@/api/create';
import AuthContext, { ACTIONS } from '@/components/AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    margin: theme.spacing(6, 0),
  },
  pinInput: {
    margin: theme.spacing(6, 0),
    maxWidth: 250,
    width: '100%',
  },
  miniTitle: {
    margin: theme.spacing(2, 0),
  },
  deviceGrid: {
    color: theme.palette.primary.dark,
  },
  keyboard: {
    maxHeight: 300,
  },
  deviceIcon: {
    width: 40,
    height: 40,
    backgroundColor: fade(theme.palette.primary.light, 0.35),
    borderRadius: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '12px',
    marginRight: '12px',
    '& svg': {
      fill: 'transparent',
      width: 12,
      height: 20,
    },
  },
}));

export type PinValidateStepProps = {};

const PinValidateStep: React.FC<PinValidateStepProps> = () => {
  const {
    state: { pinCode },
    dispatch,
  } = useContext(AuthContext);
  const { setError } = useApp();
  const [securityCode, setSecurityCode] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();
  const { deviceInfo } = useDeviceService();

  const onPinCodeSubmit = () => {
    if (pinCode !== securityCode) {
      dispatch({ type: ACTIONS.SET_STEP, payload: 4 });
      return setError(<Trans i18nKey='PinLogin.repeatPinError' />);
    }
    registerDevice({
      uuid: deviceInfo.uuid,
      platform: 'MOBILE',
      platformVersion: deviceInfo.osVersion,
      model: deviceInfo.model,
      name: deviceInfo.name || deviceInfo.model,
      loginCode: pinCode,
    })
      .then(async (data) => {
        const stringifiedData = JSON.stringify(data);
        if (stringifiedData.includes('SUCCESS')) {
          dispatch({ type: ACTIONS.INCREMENT_STEP });
        } else if (stringifiedData.includes('ALREADY_REGISTERED')) {
          setSecurityCode('');
          setError(<Trans i18nKey='PinLogin.alreadyRegistered' />);
        } else {
          setSecurityCode('');
          setError(<Trans i18nKey='PinLogin.error' />);
        }
      })
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);
        setSecurityCode('');
        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      });
  };

  const onKeyboardTouch = (key: string) => {
    if (key === 'remove') {
      setSecurityCode((prev) => prev.slice(0, -1));
    } else {
      const value = [securityCode, key].join('');
      if (value.length > 4) return;

      setSecurityCode(value);
    }
  };

  useEffect(() => {
    if (securityCode.length === 4) {
      onPinCodeSubmit();
    }
  }, [securityCode]);

  return (
    <Grid container direction='column' className={classes.stepContainer}>
      <Typography
        className={classes.description}
        variant='subtitle2'
        color='textSecondary'
      >
        {t('PinLogin.Step5Description')}
      </Typography>
      <Typography
        variant='subtitle1'
        color='textPrimary'
        className={classes.miniTitle}
      >
        {t('PinLogin.yourDevice')}
      </Typography>
      <Grid container alignItems='center' className={classes.deviceGrid}>
        <Grid item className={classes.deviceIcon}>
          <SmartPhone />
        </Grid>
        <Typography variant='subtitle2' color='primary'>
          {deviceInfo?.name || deviceInfo?.model}
        </Typography>
      </Grid>
      <Typography
        variant='subtitle1'
        color='textPrimary'
        className={classes.miniTitle}
      >
        {t('PinLogin.repeatPin')}
      </Typography>
      <Grid container direction='column' alignItems='center'>
        <Box className={classes.pinInput}>
          <PinNumberInput
            variant='input'
            disableKeyboard
            inputProps={{
              value: securityCode
            }}
          />
        </Box>
      </Grid>
      <MobileKeyboard
        classes={{ root: classes.keyboard }}
        faceId={false}
        onKeyDown={(value: string) => onKeyboardTouch(value)}
      />
    </Grid>
  );
};

export default PinValidateStep;
